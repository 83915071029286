import React, { useState } from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

import componentStyles from "./blog.module.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import { useBlogPosts } from "../hooks"
import BlogPostCard from "../components/BlogCard/BlogPostCard"

const types = [
  {
    name: "all",
    translatePath: "blog.post-types.all",
  },
  {
    name: "news",
    translatePath: "blog.post-types.news",
  },
  {
    name: "articles",
    translatePath: "blog.post-types.articles",
  },
]

const Blog: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const blogPosts = useBlogPosts(intl.locale)

  const [currentType, currentTypeSetter] = useState("all")

  const blogClasses = classNames(componentStyles.blog, "container")
  const blogTitleClasses = classNames(
    componentStyles.blogTitle,
    "large-section-title"
  )
  const blogWrapperClasses = classNames(componentStyles.blogWrapper)
  const blogItemClasses = classNames(componentStyles.blogItem)
  const blogListClasses = classNames(componentStyles.blogList)
  const blogPostSectionClasses = classNames(
    componentStyles.blogPostSection,
    "container"
  )

  const postTypeModeChange = event => {
    if (event && event.target && event.target.id) {
      currentTypeSetter(event.target.id)
    }
  }

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "blog.seo.description" })}
        title={intl.formatMessage({ id: "blog.seo.title" })}
        pathname={location.pathname}
      />
      <section className={blogClasses}>
        <h1 className={blogTitleClasses}>
          {intl.formatMessage({ id: "blog.title" })}
        </h1>
        <fieldset className={componentStyles.postTypeGroup}>
          <legend className={componentStyles.postTypeLegend}>
            Post type selector
          </legend>

          {types.map(({ name, translatePath }) => (
            <div key={name}>
              <input
                className={componentStyles.postTypeInput}
                type="radio"
                id={name}
                name="post-type"
                checked={name === currentType}
                onChange={postTypeModeChange}
              />
              <label className={componentStyles.postTypeLabel} htmlFor={name}>
                {intl.formatMessage({ id: translatePath })}
              </label>
            </div>
          ))}
        </fieldset>
      </section>
      <section className={blogPostSectionClasses}>
        <div className={blogWrapperClasses}>
          <ul className={blogListClasses}>
            {blogPosts.edges
              .filter(({node} ) => {
                const { type } = node.frontmatter
                switch (currentType) {
                  case "all":
                    return true
                  default:
                    return type === currentType
                }
              })
              .filter(
                (post, index) => index < 30
              )
              .map(({ node }) => {
                const {
                  date,
                  title,
                  externalLink,
                  thumbnail,
                } = node.frontmatter
                const { slug } = node.fields
                return (
                  <li key={slug} className={blogItemClasses}>
                    <BlogPostCard
                      className={componentStyles.blogPostCard}
                      date={date}
                      title={title}
                      externalLink={externalLink}
                      slug={slug}
                      thumbnail={thumbnail}
                    />
                  </li>
                )
              })}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default Blog
